@import "./theme.scss";

@font-face {
    font-family: interReg;
    src: url('./../../assets/inter/Inter-Regular.ttf');
}
@font-face {
    font-family: interSemiBold;
    src: url('./../../assets/inter/Inter-SemiBold.ttf');
}
@font-face {
    font-family: interBold;
    src: url('./../../assets/inter/Inter-Bold.ttf');
}
@font-face {
    font-family: interExtraBold;
    src: url('./../../assets/inter/Inter-ExtraBold.ttf');
}
@font-face {
    font-family: interLight;
    src: url('./../../assets/inter/Inter-Light.ttf');
}
@font-face {
    font-family: interMedium;
    src: url('./../../assets/inter/Inter-Medium.ttf');
}
@font-face {
  font-family: interBlack;
  src: url('./../../assets/inter/Inter-Black.ttf');
}
@font-face {
  font-family: interExtraLight;
  src: url('./../../assets/inter/Inter-ExtraLight.ttf');
}
.cursor-pointer{
    cursor: pointer;
}

/* width */
::-webkit-scrollbar {
    width: 0.1px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $white;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey-text;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $white;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }

  button:disabled {
    background-color: $grey-text !important;
    border-color: $grey-text !important;
    color: $white !important;
    cursor: default !important;
  }
  button{
    cursor: pointer;
  }
  .close{
    opacity: 1 !important;
  }
    .mat-select-panel{
      margin-top: 35px;
    }

  // .custom-slider {
  //   .ngx-slider {
  //     .ngx-slider-pointer {
  //       background-color: $brand-warning-color !important;
  //     }
  //     .ngx-slider-pointer:after {
  //       background-color: white !important;
  //     }
  //     .ngx-slider-selection {
  //       background-color: $brand-warning-color !important;
  //     }
  //   }
  //   .ngx-slider .ngx-slider-pointer:after{
  //     content: none;
  //   }
  //   .ngx-slider .ngx-slider-pointer{
  //     width: 25px !important;
  //     height: 25px !important;
  //     top: -12px !important;
  //   }
  // }
  tr.mat-row, tr.mat-footer-row{
    height: 45px !important;
  }
  .slick-dots {
    position: relative !important;
    margin-top: 15px !important;
  }

 
  @media only screen and (min-width: 1200px)  and (max-width: 1500px){
    table{
      overflow: scroll;
      width: 115% !important;
    }
  }
  
  // .faq-header{
  //   .mat-expansion-indicator::after{
  //     content: '-';
  //   }
  //   .mat-expansion-indicator::before
  // }
  .mat-dialog-container{
    // background-color: $light-grey;
    border-radius: 10px  !important;
  }
  .question-answers{
    .mat-accordion .mat-expansion-panel:last-of-type{
      border-bottom-right-radius: 0px; 
      border-bottom-left-radius: 0px; 
      border-bottom: 0.5px solid #EAEAEA;
    }
    .mat-accordion .mat-expansion-panel:first-of-type{
      border-bottom-right-radius: 0px; 
      border-bottom-left-radius: 0px; 
    }
    .mat-expansion-panel:not([class*=mat-elevation-z]){
      box-shadow: none;
    }
    mat-expansion-panel-header .mat-expansion-indicator::after {
      content: "+";
      border: 0;
      transform: none;
      background-color: $theme-secondary;
      width: 30px;
      height: 30px;
      text-align: center;
      @include text-size(17px, interSemiBold, $theme-primary , normal, 500);
    }
    
    .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
      content: "-";
      @include text-size(17px, interSemiBold, $theme-primary , normal, 500);
    }
  }
  .check-fields{
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: #024751 !important;
    }
  }

  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb{
    background-color: $white !important;
  }
  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar{
    background-color: $theme-primary !important;
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-full-width{
  .mat-form-field-wrapper{
    padding-bottom: 2px;
  }
}
.mat-option:hover {
   background-color: $theme-primary !important;
   color: $white !important;
 }
.mat-calendar-body-cell{
  outline: none !important;
}
.mat-icon-button{
  outline: none !important;
}
input[type=file],/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}
.delete-icon,.edit-icon{
  .material-icons{
    font-size: 20px !important;
  }
}